.WebProjects{
    height: 95vh;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.WebProjects-website-wraper{
    width: 50%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.WebProjects-website-img{
    height: auto;
    width: 95%;
    border-radius: 1em;
}
.reveal {
    opacity: 0;
    transition: all 500ms ease;
    transform: scale(90%);
}
.reveal-active{
    opacity: 1;
    transform: scale(100%);
}
.WebProjects-info-wraper{
    width: 50%;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 0.2em;
}
.WebProjects-info-app-logo{
    width: 5em;
    height: auto;
}
.WebProjects-info-name{
    text-transform: capitalize;
}
.WebProjects-info-wraper > span {
    padding: 0.5em;
    height: 2.5em;
    width: 100%;
}
.WebProjects-info-about{
    text-align: center;
}

@media only screen and (max-width: 850px)  {
    .WebProjects{
        flex-direction: column;
    }
    .WebProjects-website-wraper{
        width: 100%;
    }
    .WebProjects-website-img{
        width: 80%;
        border-radius: 1em;
    }
    .WebProjects-info-wraper{
        width: 100%;
        padding: 0.2em;
    }
    .WebProjects-info-app-logo{
        width: 2.5em;
        height: auto;
    }
    .WebProjects-info-name{
        font-size: 1.5em;
        text-transform: capitalize;
    }
    .WebProjects-info-about{
        font-size: 0.8em;
    }
}