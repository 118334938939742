.Contact{
    min-height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em 0em;
    overflow: hidden;
    position: relative;
}
.Contact-form{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 20em;
    aspect-ratio: 1/1.25;
    border-radius: 1em;
    padding: 1em;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 10px 10px 10px rgba(63, 65, 88, 0.05);
    backdrop-filter: blur(2.5px);
    border-radius: 1em;
    border: solid 1px transparent;
    background-clip: padding-box;
}
.inputfild-wraper{
    width: 100%;
    border: 1px solid black;
    border-radius: 0.25em;
    padding: 0.1em;
}
.fildName{
    text-transform: capitalize;
    color: black;
    padding: 0em 0.5em;
    margin-left: 1em;
}
.inputfild-wraper > input,
.inputfild-wraper > textarea{
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    outline: none;
}
.formfild{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 1em;
    width: 100%;
}
.emailSubmit-btn{
    padding: 0.5em 1em;
    border: none;
    text-transform: capitalize;
    background-color: transparent;
    border-radius: 0.5em;
    box-shadow: 2px 2px 5px rgba(22, 34, 48, 0.5);
}
.emailSubmit-btn:hover,
.emailSubmit-btn:active{
    box-shadow: 2px 2px 2.5px rgba(22, 34, 48, 0.6);
}

.animation-bg{
    min-height: 150vh;
    min-width: 150vw;
    position: absolute;
    height: 200%;
    width: 200%;
    background-color: #e5e5f7;
    opacity: 0.8;
    background-image:  radial-gradient(#0079fd 2px, transparent 2px), radial-gradient(#0079fd 2px, #e5e5f7 2px);
    background-size: 80px 80px;
    background-position: 0 0,40px 40px;
    scale: 1.5;
    animation: animatebg 100s infinite linear;
}

@keyframes animatebg {
    0%{
        transform: rotate(0deg);
    }
    50%{
        transform: rotate(360deg);
    }
    100%{
        transform: rotate(0deg);
    }
}
