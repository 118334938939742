.PrivacyPolicy{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.PrivacyPolicy_warper{
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 860px;
    gap: 1em;
    color: rgb(80, 80, 80);
    background-color: rgb(250, 250, 250);
}
.PrivacyPolicy_warper li{
    list-style: disc;
    margin-left: 2em;
}