.Loading{
    position: fixed;
    z-index: 10;
    height: 105vh;
    width: 100vw;
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(93, 100, 116, 0.5);
    box-shadow: 10px 10px 10px rgba(63, 65, 88, 0.05);
    backdrop-filter: blur(2.5px);
}
.loader-wraper{
    background-color: rgb(211, 211, 211);
    box-shadow: 5px 5px 5px rgb(125, 125, 125),
                -5px -5px 5px rgb(231, 231, 231);
    height: 5em;
    width: 5em;
    border-radius: 0.8em;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.loader-1,
.loader-2{
    position: absolute;
    border: 0.5em solid #0079fd;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-radius: 50%;
    height: 80%;
    width: 80%;
    animation: loader-spin 1s infinite linear;
}
.loader-2{
    border: 0.25em solid #0079fd;
    border-top-color: transparent;
    border-bottom-color: transparent;
    height: 50%;
    width: 50%;
    animation: loader-spin 800ms infinite linear reverse;
}
@keyframes loader-spin {
    0%{
        transform: rotate(0deg);
    }
    50%{
        transform: rotate(180deg);
    }
    100%{
        transform: rotate(360deg);
    }
}