.Portfolio{
    min-height: 80vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
}
.portfolio-bg{
    min-height: 150vh;
    min-width: 150vw;
    height: 500%;
    width: 500%;
    animation: animatebg 100s infinite linear;
    z-index: -1;
}
.Portfolio-content{
    width: 100%;
    padding: 0em 5em;
} 
.Portfolio-card-wraper{
    width: 100%;
    border: 0px solid #0079fd;
    border-left-width: 5px;
    padding: 1em 1em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}
.Portfolio-card-wraper::before{
    content: '';
    position: absolute;
    height: 1em;
    width: 1em;
    border-radius: 50%;
    background-color: #0079fd;
    left: 0px;
    transform: translateX(-60%);
}
.Portfolio-card{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 1em;
    padding: 1em;
    background: rgba(0, 122, 253, 0.1);
    box-shadow: 10px 10px 10px rgba(63, 65, 88, 0.02);
    backdrop-filter: blur(2.5px);
    border-radius: 1em;
    border: solid 1px transparent;
    background-clip: padding-box;
}
.Portfolio-card-logo{
    height: auto;
    width: 5em;
}
.Portfolio-card-github{
    height: auto;
    width: 2.5em;
}

@media screen and (max-width: 500px) {
    .Portfolio-content{
        width: 100%;
        padding: 0em 1em;
    } 
    .Portfolio-card-startdate{
        font-size: 0.8em;
    }
    .Portfolio-card-logo{
        height: auto;
        width: 2.5em;
    }
    .Portfolio-card-github{
        height: auto;
        width: 1.25em;
    }
  }