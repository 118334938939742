footer{
    display: flex;
    flex-direction: column;
    gap: 1em;
    min-height: 5em;
    width: 100%;
    padding: 1em;
    align-items: center;
    justify-content: center;
    color: rgb(250, 250, 250);
    background-color: rgb(0, 0, 0);
}
footer a {
    color: rgb(255, 255, 255);
}
.socialMedia-icon-wraper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1em;
}
.socialMedia-icon{
    height: 2em;
    width: auto;
}
.instagram:hover,
.instagram:active{
    fill: #E4405F;
}
.facefook:hover,
.facefook:active{
    fill: #1877F2;
}
.github:hover,
.github:active{
    fill: #585858;
}
.hackster:hover,
.hackster:active{
    fill: #2E9FE6;
}
.linked:hover,
.linked:active{
    fill: #0A66C2;
}
.CVdownload:hover,
.CVdownload:active{
    fill: #FFCC22;
}
.CV{
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1em;
    text-transform: capitalize;
}

@media only screen and (max-width: 850px)  {
    footer{
        padding: 1em;
        font-size: 0.8em;
    }
}