.Projects{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap: 1em;
    padding: 1em;
    overflow: hidden;
    position: relative;
}
.projects-bg{
    min-height: 150vh;
    min-width: 150vw;
    height: 250%;
    width: 250%;
    animation: animatebg 100s infinite linear;
}

 