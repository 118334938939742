header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 5em;
    padding: 1em;
    background-color: #0079fd;
    width: 100%;
    transition: 250ms;
    margin: auto;
    z-index: 5;
}
/* for mobile */
.header-mob{
    flex-direction: column;
}
.outHeader{
    width: 95%;
    margin: auto;
    z-index: 5;
    position: sticky;
    top: 1em;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 10px 10px 10px rgba(63, 65, 88, 0.02);
    backdrop-filter: blur(20px);
    border-radius: 1em;
    border: solid 1px transparent;
    background-clip: padding-box;
}
.logo-AND-menubtn{
    height: 2.8em;
    display: flex;
    flex-direction: row;
    align-items: center;
}
/* for mobile */
.logo-AND-menubtn-mob{
    width: 100%;
    justify-content: space-between;
}
.menu-btn{
    cursor: pointer;
    display: none;
    height: 100%;
}
/* for mobile */
.menu-btn-mob{
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-icon{
    /* height: 1em; */
    width: 2.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25em;
    position: relative;
}
.menu-icon > span{
    background-color: rgb(0, 0, 0);
    height: 0.5em;
    width: 100%;
    border-radius: 0.25em;
    transition: 250ms;
}
.menu-icon-close > span:nth-child(1){
    position: absolute;
    transform: rotate(45deg);
}
.menu-icon-close > span:nth-child(2){
    display: none;
}
.menu-icon-close > span:nth-child(3){
    position: absolute;
    transform: rotate(-45deg);
}
.image-wraper{
    height: 100%;
}
.Header-logo{
    /* height: 2.8em; */
    height: 100%;
    width: auto;
}
.links{
    color: #000000;
}
.links-wraper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    text-transform: capitalize;
}
/* for mobile */
.links-wraper-mob-vis{
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: 5;
}
/* for mobile */
.links-wraper-mob-invi{
    display: none;
}
.navication-vis{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1em;
}
.navication-invis{
    display: none;
}
.project_Search_Bar{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8em;
}
.project_Search_Bar > input{
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 1em;
    padding: 0.5em;
    background: transparent;
    border: 1px solid black;
    text-transform: capitalize;
    text-align: center;
}