.SkillsAndTools{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    text-transform: capitalize;
    padding: 1em 0em;
}
.SkillsAndTools::after,
.SkillsAndTools::before{
    content: '';
    position: absolute;
    height: 100%;
    width: 5em;
    z-index: 2;
    background: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0.8), transparent);
    right: 0px;
}
.SkillsAndTools::before{
    left: 0px;
    background: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0.8), transparent);
}
.SkillsAndTools-img-holder{
    background-color: rgb(255, 255, 255);
    width: 100%;
    padding: 1em;
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
    align-items: center;
    scroll-behavior: smooth;
    scrollbar-width: none;
    padding-left: 5em;
    padding-right: 5em;
}
.SkillsAndTools-img-holder::-webkit-scrollbar {
    width: 0px;
}
.SkillsAndTools-img-holder > span {
    display: flex;
    flex-direction: row;
    gap: 5em;
    animation: scroletoleft 50s infinite linear;
}
@keyframes scroletoleft {
    0% {
        transform: translateX(0%)
    }
    50% {
        transform: translateX(-100%)
    }
    100% {
        transform: translateX(0%)
    }
}
.SkillsAndTools-img{
    height: 5em;
    width: auto;
}

@media only screen and (max-width: 500px)  {
    .SkillsAndTools-name{
        font-size: 1.8em;
    }
    .SkillsAndTools-img{
        height: 2.5em;
        width: auto;
    }
}