.App {
  text-align: center;
  /* width: 100%; */
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
canvas{
  border: 1px solid black;
}