.IntroSection{
    height: 100vh;
    width: 100%;
    background-color: #0079fd;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0em 1em;
}
.intro-text{
    text-align: start;
    text-transform: capitalize;
    color: white;
    line-height: 2em;
    font-size: 2em;
}
.intro-text-wraper,
.intro-img-wraper{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.introimg{
    width: 60%;
    height: auto;
}

/* for the robot svg */
.cls-1{fill:none;}.cls-2{fill:#fff;}.cls-3{fill:#ff8d37;}
.intro-img-wraper{
    position: relative;
    animation: example 2500ms infinite ease-in-out;
}
/* #{} */
/* #Head{
} */
/* #body{
} */
#left_eye{
    transform: translateX(9px);
}
@keyframes example {
    0% {transform: translateY(0%);}
    50% {transform: translateY(5%);}
    100% {transform: translateY(0%);}
  }
/* for the robot svg */

@media screen and (max-width: 500px) {
    .IntroSection{
        height: 95vh;
        flex-direction: column-reverse;
        padding: 0em;
    }
    .intro-text{
        line-height: 2em;
        font-size: 2em;
    }
    .intro-text-wraper,
    .intro-img-wraper{
        width: 95%;
    }
    .introimg{
        width: 80%;
    }
}