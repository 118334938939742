.FourOFour{
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: hsl(211, 100%, 50%);
    color: rgb(255, 255, 255);
    animation: fof 2500ms infinite ease-in-out;
}
.FourOFour-img{
animation: example 2500ms infinite ease-in-out;
    
}
/* for the robot svg */
.FourOFour-img .cls-1{fill:none;}
.FourOFour-img .cls-2{fill:#fff;}
.FourOFour-img .cls-3{fill:#ff0000;}

@keyframes example {
    0% {transform: translateY(0%);}
    50% {transform: translateY(5%);}
    100% {transform: translateY(0%);}
  }
@keyframes fof {
    0% {letter-spacing: 0px;}
    50% {letter-spacing: 8px;}
    100% {letter-spacing: 0px;}
  }