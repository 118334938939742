.ProjectCard{
    background-color: rgba(37, 131, 255, 0.5);
    border-radius: 0.8em;
    width: 20em;
    /* height: 25em; */
    aspect-ratio: 1 / 1.25 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-transform: capitalize;
    padding: 1em;
    z-index: 0;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 10px 10px 10px rgba(63, 65, 88, 0.05);
    backdrop-filter: blur(2.5px);
    border-radius: 1em;
    border: solid 1px transparent;
    background-clip: padding-box;
    transition: 2s;
}
.ProjectCard-logo{
    height: auto;
    width: 5em;
}
.ProjectCard-github{
    height: auto;
    width: 2.5em;
}
@media screen and (max-width: 500px) {
    .ProjectCard{
        border-radius: 0.8em;
        width: 10em;
        padding: 0.5em;
        border-radius: 0.5em;
        border: solid 0.5px transparent;
    }
    .ProjectCard-logo{
        width: 2.5em;
    }
    .ProjectCard-name{
        font-size: 0.8em;
    }
    .ProjectCard-github{
        width: 1.25em;
    }
  }